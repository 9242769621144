.container {
  position: relative;
  padding-bottom: 1em;
  font-family: var(--ff-bull-text);
}

.title {
  display: block;
  position: relative;
  transition: border-bottom-color 0.3s;
  cursor: pointer;
  margin-bottom: 0.5em;
  border: none;
  background: none;
  padding-bottom: 0.3em;
  width: 100%;
  color: var(--page-theme-contrast-color);
  font-weight: 600;
  font-size: var(--font-size-32px);
  text-align: left;
}

.title::after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  background-color: var(--page-theme-contrast-color);
  width: 100%;
  height: 1px;
  content: " ";
}

.title svg line {
  stroke: var(--page-theme-contrast-color);
}

.title span {
  display: inline-block;
  max-width: 98%;
}

.title:hover {
  opacity: 1;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  inset-inline-end: 0; /* RTL */
  width: 1em;
  height: auto;
}

.description {
  display: grid;
  position: relative;
  grid-template-rows: 1fr;
  transition: 300ms linear;
  transition-property: opacity, grid-template-rows;
  padding-bottom: 0.5em;
  max-width: 25em;
  font-size: var(--font-size-20px);
  line-height: 1.3;
}

.description--closed {
  grid-template-rows: 0fr;
  opacity: 0;
}

.description-inner {
  overflow: hidden;
}

.description p {
  opacity: 0.8;
  margin: 0;
  padding: 0;
}
