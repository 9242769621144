/* stylelint-disable selector-pseudo-class-no-unknown */

/* NOTE: This was a rushed project, there is likely to be poor CSS in places */

/* Disable body scroll when overlay is open */
body:has(.overlay) {
  overflow: hidden;
}

/**
 * Override React Modal overlay:
 * https://reactcommunity.org/react-modal/styles/
 */
.overlay {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 100; /* large number to go over athlete quote stack */
  isolation: isolate;
}

/**
 * Override React Modal container:
 * https://reactcommunity.org/react-modal/styles/
 */
.container {
  --z-index-cracks: 5;
  --z-index-ice: 1;
  --z-index-ice2: 4;
  --z-index-product: 3;
  --z-index-text: 7;
  --z-index-button: 10;
  --z-index-dot: 6;
  --transition-delay-ice: 0.5s;
  --transition-delay-product: 0.5s;
  --transition-delay-heading: 1.5s;
  --transition-delay-text: 2s;
  --transition-delay-dot: 5s;
  --transition-crack: opacity 1s linear;
  --transition-ice: opacity 1s ease-in-out;
  --transition-product: opacity 1s ease-in-out;
  --transition-heading: opacity 1s ease-in-out;
  --transition-text: opacity 1s ease-in-out;
  --transition-dot: opacity 1s ease-in-out;

  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: stretch;
  align-items: stretch;
  opacity: 0;
  mask-repeat: no-repeat;
  transition: opacity 1s ease-in-out;
  margin: 0;
  background-color: rgba(83, 178, 194, 1); /* Brand colour for winter */
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container:global(.ReactModal__Content--after-open) {
  opacity: 1;
}

.flash {
  opacity: 0 !important;
}

.flash * {
  opacity: 0;
  transition: opacity 0s ease-in-out;
}

.flash::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 1000; /* Ensure it covers the modal content */
  animation: flash 0.5s ease-in-out;
  background-color: white;
  content: "";
}

.container:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
  transition: opacity 0s linear;
}

.inner-ice {
  display: flex;
  align-items: center;
  cursor: wait;
  width: 100%;
}

/* After some initial amimations the div becomes clickable */
.inner-ice[data-clickable="true"] {
  cursor: pointer;
}

.ice-image {
  position: absolute;
  opacity: 0;
  z-index: var(--z-index-ice);
  transition: var(--transition-ice);
  transition-delay: var(--transition-delay-ice);
  width: 100%;
  height: 100%;
  object-fit: cover;

  :global(.ReactModal__Content--after-open) & {
    opacity: 1;
  }
}

.ice-image2 {
  z-index: var(--z-index-ice2);

  :global(.ReactModal__Content--after-open) & {
    opacity: 0.4;
  }
}

.crack-image {
  position: absolute;
  opacity: 1;
  z-index: var(--z-index-cracks);
  mix-blend-mode: color-burn;
  mask-image: radial-gradient(
    circle,
    black 0%,
    transparent var(--mask-progress)
  );
  mask-repeat: no-repeat;
  transition: var(--transition-crack);
  background-position: center;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (width >= 650px) {
    right: calc((100vw / 4) * -1);
  }

  /* 1350px is the --max-width variable */
  @media (width >= 1350px) {
    right: calc((var(--max-width) / 4) * -1);
  }
}

.heading {
  opacity: 0;
  transition: var(--transition-heading);
  transition-delay: var(--transition-delay-heading);
  font-weight: var(--font-weight-heading);

  /* https://utopia.fyi/type/calculator/?c=380,45,1.2,1200,105,1.25,0,0,&s=0.75%7C0.5%7C0.25,1.5%7C2%7C3%7C4%7C6,s-l&g=s,l,xl,12 */
  font-size: clamp(2.8125rem, 1.0747rem + 7.3171vw, 6.5625rem);
  font-family: var(--font-family-heading);
  text-wrap: pretty;
  line-height: 1;
  text-align: center;

  @media (width >= 650px) {
    max-width: 75%;
    text-align: start;
  }
}

:global(.ReactModal__Content--after-open) .heading {
  opacity: 1;
}

.content {
  position: relative;
  z-index: var(--z-index-text);
  margin-top: auto;
  margin-bottom: auto;
  margin-inline: auto;
  padding: 40px;
  padding-inline: var(--spacing-sides);
  width: 100%;
  max-width: var(--max-width);
  color: var(--color-text-light);
  user-select: none;
}

.text-wrap {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  opacity: 0;
  transition: var(--transition-text);
  transition-delay: var(--transition-delay-text);
  margin-top: 1rem;
  font-size: var(--fluid-type-x-large);
  line-height: 1.2;
  text-align: center;

  @media (width >= 650px) {
    flex-direction: row;
    max-width: 75%;
  }
}

.text {
  text-wrap: pretty;

  @media (width >= 650px) {
    text-align: start;
  }
}

.icon-wrap {
  position: relative;
  margin-top: 3rem;
  margin-inline-end: 0.5rem;
  font-size: var(--fluid-type-xxxx-large);

  @media (width >= 650px) {
    margin-top: 0;
    font-size: var(--fluid-type-xx-large);
  }
}

:global(.ReactModal__Content--after-open) .text-wrap {
  opacity: 1;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.product-wrap {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  justify-content: flex-end;
  z-index: var(--z-index-product);
  margin: auto;
  width: 100%;
  max-width: var(--max-width);
}

.product-wrap-inner {
  display: flex;
  justify-content: center;
  margin-top: var(--navbar-height);
  width: 100%;

  @media (width >= 650px) {
    width: 50%;
  }
}

.product {
  opacity: 0;
  filter: blur(5px);
  transition: var(--transition-product);
  transition-delay: var(--transition-delay-product);
  width: 200px;

  @media (width >= 650px) {
    width: 250px;
  }
}

:global(.ReactModal__Content--after-open) .product {
  opacity: 1;
}

/* Close Button */
.close-button {
  position: absolute;
  top: calc(var(--navbar-height) + 20px);
  right: 20px;
  z-index: var(--z-index-button);
}

.close-button::part(base) {
  box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.6);
}

/* End Close Button */

/* Dot */
.dot {
  --_pulseSize: 28px;
  --_dotBg: rgba(255, 255, 255, 0.65);
  --_dotTransparentBg: rgba(255, 255, 255, 0);

  display: none; /* hidden on mobile */
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  z-index: var(--z-index-dot);
  transition: var(--transition-dot);
  transition-delay: var(--transition-delay-dot);

  :global(.ReactModal__Content--after-open) & {
    opacity: 1;
  }

  /* Hide dot after first click */
  .inner-ice[data-clicked="true"] & {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    transition-delay: initial;
  }

  @media (width >= 650px) {
    display: block;
  }
}

.dot::after {
  position: absolute;
  animation: pulsing 2s infinite;
  box-shadow: 0 0 0 var(--_dotBg);
  border-radius: 9999px;
  background-color: transparent;
  width: var(--_pulseSize);
  height: var(--_pulseSize);
  content: "";

  @media (width >= 650px) {
    right: calc((100vw / 4) * -1);
    transform: translateX(50%);
    background-color: var(--_dotBg);
  }

  /* 1350px is the --max-width variable */
  @media (width >= 1350px) {
    right: calc((var(--max-width) / 4) * -1);
  }
}

@keyframes pulsing {
  from {
    box-shadow: 0 0 0 0 var(--_dotBg);
  }

  70% {
    box-shadow: 0 0 0 var(--_pulseSize) var(--_dotTransparentBg);
  }

  to {
    box-shadow: 0 0 0 0 var(--_dotTransparentBg);
  }
}

/* End Dot */

/* stylelint-enable selector-pseudo-class-no-unknown */
