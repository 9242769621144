.container {
  position: relative;
}

.listbox {
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: var(--elevation-2-above);
  border-radius: 4px;
  background-color: var(--color-surface-solid-light-lighter);
  padding: var(--spacing-xx-tight);
  width: auto;
  max-height: 320px;
  overflow: auto;
}

.icon {
  transition: transform 250ms ease-in-out;
}

@media (prefers-reduced-motion) {
  .icon {
    transition: inital;
  }
}

.button {
  --cosmos-button-min-width: var(--button-min-width);
}

.button[aria-expanded] .icon {
  transform: rotateX(180deg);
}

/* Force Aria attributes */
[aria-expanded] + .listbox {
  position: absolute;
  margin-top: 8px;
  min-width: var(
    --button-min-width
  ); /* optionally set a max width, text clamped to a single line */
}

.listbox__option {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--spacing-tight) * 0.5);
  cursor: default;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  border: 0;
  border-radius: 4px;
  background: none;
  padding: calc(var(--spacing-tight) * 0.5) var(--spacing-tight);
  width: 100%;
  max-width: 100%;
  user-select: none;
}

a.listbox__option {
  position: relative; /* for child absolute position */
  color: var(--color-text);
  text-decoration: none;
}

.listbox__option:hover {
  background: var(--color-surface-glass-dark-5);
}

a.listbox__option:hover {
  cursor: pointer;
}

a.listbox__option:focus-visible::before {
  position: absolute;
  inset: calc(0px);
  box-shadow: var(--focus-box-shadow);
  border-radius: calc(6px);
  content: "";
}

.listbox__option-content {
  display: flex;
  align-items: center;
  gap: calc(var(--spacing-tight) * 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listbox__option-suffix,
.listbox__option-prefix {
  --cosmos-icon-color: var(--color-text);

  flex: 0 0 auto;
  width: 18px;
  height: 18px;
}

.listbox__option-suffix {
  --cosmos-icon-color: var(--color-text-subtle);
}
