.container * {
  box-sizing: border-box;
  user-select: none; /* TODO: Why has this been set? */
  -webkit-tap-highlight-color: transparent;
}

/** 
 * 1: if there is a long list on the vendors the "button select dropdown" gets cutoff by "area-bottom".
 *    On mobile the can has to be under the buttons, on desktop it must be above.
 */

.container {
  --_z-index-top: initial; /* 1 */
  --_z-index-can: initial; /* 1 */
  --_min-height: 800px;

  position: relative;
  z-index: 1;
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color);
  isolation: isolate; /* Create a stacking-context so that negative z-indexes don't "leak" out of this component. */
  width: 100%;
  overflow: hidden;
  color: var(--page-theme-contrast-color);
  font-size: 1.4vw; /* TODO: this should use Clamp() */
  font-family: var(--ff-bull-text);
}

@media (width >= 500px) {
  .container {
    --_z-index-top: 1;
    --_z-index-can: 2;

    margin-bottom: var(--spacing-bottom);
    font-size: 1vw;
  }
}

@media (width >= 1300px) {
  .container {
    margin-bottom: 0;
  }
}

@media (width >= 1600px) {
  .container {
    font-size: var(--font-size-16px);
  }
}

.area-top {
  position: relative;
  z-index: var(--_z-index-top);
  width: 100vw;
  height: 100lvh;
  min-height: var(--_min-height);
}

.area-between {
  position: relative;
  height: 200lvh;
}

@media (width >= 500px) {
  .area-between {
    height: unset;
  }
}

.wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1600px;
  height: 100%;
}

.contents {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5vh 20px 20px 20px;
  width: 100%;
  height: 100%;
}

.contents-text {
  width: 100%;
  max-width: 62em;
}

@media (width >= 500px) {
  .contents-text {
    position: absolute;
    top: 50%;
    left: 10.5%;
    transform: translate(0, -60%);
    max-width: 51em;
  }

  html[dir="rtl"] .contents-text {
    left: 0;
    width: 50%;
  }
}

.kicker {
  margin-bottom: var(--spacing-tight);
}

[data-has-custom-theme] .kicker {
  --kicker-border-color: var(--theming-kicker-border-color);
}

.contents-bigline {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-weight: var(--font-weight-heading);
  font-size: 7.5em;
  line-height: 1.1em;
  font-family: var(--font-family-heading);
  letter-spacing: -0.025em;
}

html[dir="rtl"] .contents-bigline {
  padding-right: 5px;
  font-size: 7em;
  line-height: 1.5em;
}

@media (width >= 500px) {
  .contents-bigline {
    font-size: 6.5em;
  }

  html[dir="rtl"] .contents-bigline {
    font-size: 4.2em;
  }
}

.contents-char {
  position: relative;
  transform: translateY(0%);
}

.contents-sublines {
  margin-top: 4em;
  padding-left: 0;
}

@media (width >= 500px) {
  .contents-sublines {
    margin-top: 2em;
    padding-left: 0;
  }
}

.contents-subline {
  margin: 0;
  padding: 0;
  width: 22em;
  max-width: 95%;
  font-size: 4em;
  line-height: 1.3em;
  font-family: var(--ff-bull-text);
  letter-spacing: 0.015em;
}

.contents-subline-1 {
  font-weight: 600;
}

.contents-subline-2 {
  opacity: 0.8;
  margin-top: 0.6em;
  font-weight: 200;
  font-size: 2.6em;
}

@media (width >= 500px) {
  .contents-subline {
    font-size: 1.8em;
  }

  .contents-subline-2 {
    font-size: 1.2em;
  }
}

.button-wrapper {
  display: flex;
  column-gap: var(--button-wrapper-column-gap);
  row-gap: var(--button-wrapper-row-gap);
  flex-wrap: wrap;
  margin-top: var(--button-wrapper-block-start);
}

.can-area {
  position: absolute;
  top: 40vh;
  left: 0;
  z-index: var(--_z-index-can);
  width: 100vw;
  height: 100lvh;
  min-height: var(--_min-height);
  pointer-events: none;
}

@media (min-height: 700px) {
  .can-area {
    top: 60vw;
  }
}

@media (width >= 500px) {
  .can-area {
    top: 0;
  }
}

.can-trigger {
  position: absolute;
  top: 40vh;
  left: 0;
  width: 100%;
  height: 1px;
  pointer-events: none;
}

@media (min-height: 700px) {
  .can-trigger {
    top: 60vw;
  }
}

@media (width >= 500px) {
  .can-trigger {
    top: 0;
  }
}

.can-path {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300lvh;
  min-height: calc(var(--_min-height) + 200lvh);
  pointer-events: none;
  direction: ltr;
}

@media (width >= 500px) {
  .can-path {
    height: 200lvh;
    min-height: calc(var(--_min-height) + 100lvh);
  }
}

.can-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100em;
  height: 100em;
  pointer-events: none;
}

@media (width >= 500px) {
  .can-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-7%, -50%);
    width: 45em;
    height: 45em;
  }
}

.can-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: 50% 50%;
  width: 250%;
}

.can {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media (width >= 500px) {
  .can {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.can canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
}

.can img {
  position: absolute;
  transform: scale(0.9);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.background-image {
  /**
   * 👠 Semantically it appears after `.wrapper`, but visually we want it to be behind that content
   *
   * ✝️ This image transitions from scale(0.9) -> scale(1), so it needs to be
   * intrinsically wider the viewport.
   */
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1; /* 👠 */
  width: calc(1 / 0.9 * 100vw); /* ✝️ */
}

@media (width >= 500px) {
  .background-image {
    top: 50%;
    left: 50%;
    transform: translate(-31%, -50%);
    width: 100vw;
  }
}

.area-bottom {
  position: relative;
  width: 100vw;
  min-height: 100lvh;
}

@media (width >= 500px) {
  .area-bottom {
    padding: 0;
  }
}

.ingredients-kicker-wrapper {
  display: block;
  margin-bottom: 2em;
}

.ingredients {
  position: relative;

  @media (width >= 500px) {
    max-width: 500px;
  }
}

.ingredients-wrapper {
  padding: 20px;

  @media (width >= 500px) {
    display: flex;
    position: relative;
    top: 0;
    left: 45%;
    align-items: center;
    padding: 0;
    width: 50%;
    max-width: 600px;
    height: 100%;
    min-height: 100lvh;
  }
}

@media (width >= 500px) {
  html[dir="rtl"] .ingredients-wrapper {
    right: 55%;
    transform: translate(100%, 0);
  }
}

.ingredients-title {
  margin: 0;
  padding: 0;
  width: 100%;
  color: var(--page-theme-contrast-color);
  font-size: var(--font-size-40px);
  line-height: 1.2em;
  font-family: var(--ff-bull);
  word-break: break-word;

  @media (width >= 1300px) {
    font-size: var(--font-size-60px);
  }
}

.ingredients-description {
  opacity: 0.8;
  padding: 1em 0 1.5em 0;
  max-width: 700px;
  color: var(--page-theme-contrast-color);
  font-weight: 400;
  font-size: var(--font-size-16px);
  line-height: 1.5em;
  font-family: var(--ff-bull-text);
}

@media (width >= 1300px) {
  .ingredients-description {
    font-size: var(--font-size-24px);
  }
}

.ingredients-button-wrapper {
  display: flex;
  column-gap: var(--button-wrapper-column-gap);
  row-gap: var(--button-wrapper-row-gap);
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  /* TODO: sort out the spacing on the disclosures then this margin will be correct
   * margin-top: var(--button-wrapper-block-start); */

  @media (width >= 650px) {
    flex-direction: row;
  }
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

[data-has-custom-theme] .button[kind="primary"] {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color: var(--theming-button-border-color);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
}

[data-has-custom-theme] .button[kind="link"] {
  --cosmos-button-color: var(--theming-link-button-color);
  --cosmos-button-color-hover: var(--theming-link-button-color-hover);
  --cosmos-button-border-color: var(--theming-link-button-border-color);
  --cosmos-button-border-color-hover: var(
    --theming-link-button-border-color-hover
  );
}

html[dir="rtl"] .button-icon {
  transform: scaleX(-1);
}
