/* stylelint-disable selector-pseudo-class-no-unknown */

/* Disable scrolling when modal is open */
body:has(.overlay) {
  overflow: hidden;
}

.container {
  position: absolute;
  margin-inline: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  right: 32px;
  left: 32px;
  margin-top: calc(var(--navbar-height) + 32px);
  margin-bottom: 32px;
  box-shadow: var(--elevation-1-above);
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  max-width: 900px;
  height: 100%;
  max-height: 75vh;
}

.container iframe {
  position: relative;
  z-index: 2;
  background: var(--color-surface-solid-light-lighter);
  width: 100%;
  overflow: scroll;
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;

  @media (width >= 650px) {
    top: 32px;
    right: 32px;
  }
}

.spinner {
  --cosmos-spinner-color: var(--color-text-dark-subtle);

  position: absolute;
  z-index: 1;
  margin: auto;
  inset: 0;
  width: 128px;
  height: 128px;
  color: red;
}

.overlay:global(.ReactModal__Overlay--before-close) .spinner {
  visibility: hidden;
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background-color: var(--color-surface-glass-dark-20);
}

.overlay:global(.ReactModal__Overlay) {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.overlay:global(.ReactModal__Overlay--after-open) {
  opacity: 1;
}

.overlay:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
}

/* I frame takes a second to load, this is a poor attempt to make that less jarring */
.overlay:global(.ReactModal__Overlay) iframe {
  opacity: 0;
  transition: opacity 500ms ease-in;
  transition-delay: 1000ms;
}

.overlay:global(.ReactModal__Overlay--after-open) iframe {
  opacity: 1;
}
/* stylelint-enable selector-pseudo-class-no-unknown */
